import React, { FocusEvent, useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  FormFieldInput,
  DateInput,
} from "common/components/Form/FormField.styles";
import { formatDate } from "common/utils/general";
import { ButtonType } from "@netmedi/frontend-design-system/dist/components/Button";

export type DatePickerAction = {
  title: string;
  onClick: () => void;
  type?: ButtonType;
};

export interface IProps {
  onChange: (date: Dayjs) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  actions?: DatePickerAction[];
  placeholder?: string;
  value?: Dayjs | null;
  min?: string;
  max?: string;
  initialMonth?: Dayjs;
  enableOutsideDays?: boolean;
  defaultValue?: string; // ISO date string
  tabIndex?: number;
  touched?: boolean;
  error?: boolean;
  submitFailed?: boolean;
  fullWidth?: boolean;
  "aria-label"?: string;
  "aria-required"?: boolean;
  "aria-labelledby"?: string;
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
}

const DatePicker = (props: IProps) => {
  const { placeholder, min, max, value, tabIndex, fullWidth, ...rest } = props;

  const handleDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      props.onChange(dayjs(e.currentTarget.value)),
    [props.onChange],
  );

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
    handleDateChange(e);
  };

  return (
    <DateInput fullWidth={fullWidth}>
      <FormFieldInput
        {...rest}
        type="date"
        placeholder={placeholder}
        defaultValue={value ? formatDate(dayjs(value)) : ""}
        min={min?.toString()}
        max={max?.toString()}
        tabIndex={tabIndex ?? 0}
        onChange={handleDateChange}
        onBlur={handleOnBlur}
      />
    </DateInput>
  );
};

export default DatePicker;
